import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {path: '/',name: 'home',component: Home},
  {path: '/SignIn',name: 'SignIn',component: () => import('../views/SignIn.vue')},
  {path: '/foodpig',name: 'foodpig',component: () => import('../views/iot/FoodPig.vue')},
  // Futures
  {path: '/Futures1',name: 'Futures1',component: () => import('../views/Trade/Futures1.vue')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
localStorage.setItem("URL_FILE", JSON.stringify('https://wild-puce-buffalo-tie.cyclic.app/'));
// localStorage.setItem("URL_FILE", JSON.stringify('http://localhost:7009/'));
localStorage.setItem("URL_APP", JSON.stringify('https://www.jadoi.com/'));

const publicPages = ['/SignIn','/','/foodpig','/Futures1'];
  const authRequired = !publicPages.includes(to.path); 
  const loggedIn = localStorage.getItem('token');
  if(authRequired && !loggedIn){
    next('/SignIn');
  } else{
    next();
  }
});
export default router
