<template>
  <!-- <AlertVue v-slot="Alert"> -->
    <!-- time:{{ Alert.now }}<br /> -->
    <br />
    <div class="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
      <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
        id="listGroupCheckableRadios1" value="" checked @click="openMonitor()">
      <label class="list-group-item rounded-3 py-3" for="listGroupCheckableRadios1">
        Monitor
        <span class="d-block small opacity-50">Pig farm monitor</span>
      </label>

      <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
        id="listGroupCheckableRadios2" value="" checked @click="openFutures1()">
      <label class="list-group-item rounded-3 py-3" for="listGroupCheckableRadios2">
        Trade Futures
        <span class="d-block small opacity-50">Some other text goes here</span>
      </label>

      <!-- <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
        id="listGroupCheckableRadios3" value="">
      <label class="list-group-item rounded-3 py-3" for="listGroupCheckableRadios3">
        Third radio
        <span class="d-block small opacity-50">And we end with another snippet of text</span>
      </label>

      <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
        id="listGroupCheckableRadios4" value="" disabled>
      <label class="list-group-item rounded-3 py-3" for="listGroupCheckableRadios4">
        Fourth disabled radio
        <span class="d-block small opacity-50">This option is disabled</span>
      </label> -->
    </div>
  <!-- </AlertVue> -->
</template>

<script>
import Swal from 'sweetalert2'
// import AlertVue from '@/views/Alert.vue';
export default {
  name: "Home_",
  components: {
    // AlertVue
  },
  data() {
    const roles = JSON.parse(localStorage.getItem("roles"));
    return {
      roles
    }
  },
  methods: {
    async openMonitor() {
      const { value: password } = await Swal.fire({
        title: ' ',
        input: 'password',
        inputLabel: '',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      })

      if (password === '111111') {
        // Swal.fire(`Entered password: ${password}`)
        this.$router.push('/foodpig');
      } else {
        Swal.fire({
          title: 'หยุดเดาเถอะ',
          text: '',
          imageUrl: 'http://photos1.blogger.com/blogger/4933/2592/1600/FunnyPig.0.jpg',
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
        })
      }
    }, 
    async openFutures1() {
      this.$router.push('/Futures1');
    },
  }
}
</script>

<style scoped>
.list-group {
  max-width: 460px;
  margin: 4rem auto;
}

.form-check-input:checked+.form-checked-content {
  opacity: .5;
}

.form-check-input-placeholder {
  border-style: dashed;
}

[contenteditable]:focus {
  outline: 0;
}

.list-group-checkable .list-group-item {
  cursor: pointer;
}

.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.list-group-item-check:hover+.list-group-item {
  background-color: var(--bs-light);
}

.list-group-item-check:checked+.list-group-item {
  color: #fff;
  background-color: var(--bs-blue);
}

.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.list-group-radio .list-group-item {
  cursor: pointer;
  border-radius: .5rem;
}

.list-group-radio .form-check-input {
  z-index: 2;
  margin-top: -.5em;
}

.list-group-radio .list-group-item:hover,
.list-group-radio .list-group-item:focus {
  background-color: var(--bs-light);
}

.list-group-radio .form-check-input:checked+.list-group-item {
  background-color: var(--bs-body);
  border-color: var(--bs-blue);
  box-shadow: 0 0 0 2px var(--bs-blue);
}

.list-group-radio .form-check-input[disabled]+.list-group-item,
.list-group-radio .form-check-input:disabled+.list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
</style>