import axios from "axios";
import { useStore } from "vuex";
const store = useStore();

// axios.defaults.baseURL = "https://wild-puce-buffalo-tie.cyclic.app";
axios.defaults.baseURL = "https://web-production-9a621.up.railway.app";

let refresh = false;
axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("token"))}`;
var TokenModel = {
  refreshToken: ""
};
axios.interceptors.response.use((resp) => resp,async (error) => {
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      TokenModel.refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
      const { status, data } = await axios.post("/refresh-token",TokenModel,
        { headers: { Authorization: "Bearer " + TokenModel.refreshToken } },
        { withCredentials: true }
      );

      if (status === 200) {
        localStorage.setItem("token", JSON.stringify(data.Token));
        localStorage.setItem("refreshToken", JSON.stringify(data.RefreshToken));
        axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("token"))}`;
        return axios(error.config);
      }

      // if (status === 400) {
      //   localStorage.setItem("token", null);
      //   localStorage.setItem("refreshToken", null);
      //   store.dispatch("setAuth", false);
      //   window.location.href = "/SignIn";
      //   return axios(error.config);
      // }
    }
    refresh = false;
    return error;
  }
);
